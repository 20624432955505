$(document).on('ready', function () {
  $(".no-working-select").each(function () {
    if ($(this).val() == 'image') {
      $(this).closest('.section-style').find('.dependent_field').prop('disabled', true);
    }
  })

  // window.addEventListener('DOMContentLoaded', function () {
  //   set_fontstyle_dropdown()
  // });

  $(document).on('change', '.no-working-select', function () {
    if ($(this).val() == 'image') {
      $(this).closest('.section-style').find('.dependent_field').prop('disabled', true);
    }
    else {
      $(this).closest('.section-style').find('.dependent_field').prop('disabled', false);
    }
  })

  var contineer = $('.preview_canvas_container');
  // var hidden_field_container = $('#hidden_fields');


  /*create new canvas*/
  var canvas = new fabric.Canvas('preview_canvas');


  canvas.on('after:render', function (options) {
    $("#certificate_template_canvas_data").val(JSON.stringify(canvas));
  })

  /*initialize with previous data*/
  canvas.setDimensions({ width: contineer.width(), height: contineer.height() });
  initilizeCanvas(canvas);


  /*Event listener for add text btn clicked*/
  $(document).on('click', '#add_text_btn',
    function add_text() {
      var object_length = canvas.getObjects().length;
      var textbox = new fabric.Textbox('Add text', {
        left: 50,
        top: 50,
        width: 150,
        fontSize: 20,
        fontFamily: 'Times New Roman',
        canvas_id: object_length
      });
      textbox.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            code: this.code
          });
        };
      })(textbox.toObject);
      canvas.add(textbox);
      textbox.code = "default";
      textbox.field_id = "";
      canvas.renderAll();
      set_hidden_field(canvas);
    })

  /*Event listenr for image add btn clicked */
  $(document).on('change', '#add_image_btn', function (e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    var object_length = canvas.getObjects().length;

    /*create and save image added to the field */
    save_image_to_server(canvas, file, object_length);
    var r = reader.readAsDataURL(file);
    set_hidden_field(canvas);
  });

  /* Adding Background image */
  $(document).on('change', '#add_back_image_btn', function (e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    var object_length = canvas.getObjects().length;
    save_image_to_server(canvas, file, object_length, "background");
    reader.readAsDataURL(file);
    set_hidden_field(canvas);
  });


  /* display/hide text controls */
  canvas.on('selection:created', function (e) {
    var active_obj = canvas.getActiveObject();
    set_input_fields(active_obj);
    set_hidden_field(canvas);
  });

  canvas.on('selection:updated', function (e) {
    console.log("selection changed");
    var active_obj = canvas.getActiveObject()
    set_input_fields(active_obj);
    set_hidden_field(canvas);
  })

  canvas.on('before:selection:cleared', function (e) {
    if (e.target.type === 'textbox') {
      $('#textControls').attr("disabled", true);
    }
  });

  /*Event listener for modification for canvas object */
  canvas.on('object:modified', function (options) {
    var active_obj = this.getActiveObject();
    set_hidden_field(canvas);
  });

  /* Event for font-family change */
  $(document).on('change', '#font_family', function () {
    canvas.getActiveObject().set("fontFamily", this.value);
    canvas.renderAll();
    set_hidden_field(canvas);
  })

  /*Font size changed */
  $(document).on('change', '#font_size', function () {
    canvas.getActiveObject().set("fontSize", this.value);
    canvas.renderAll();
    set_hidden_field(canvas);
  });

  /*Change of color picker */
  $(document).on('change', '#font_color', function () {
    canvas.getActiveObject().set('fill', $(this).val());
    canvas.renderAll();
    set_hidden_field(canvas);
  });

  /*Delete object */
  $(document).on('click', '#delete_obj', function () {
    canvas.remove(canvas.getActiveObject());
    canvas.renderAll();
    set_hidden_field(canvas);
  })

  /*Event for redio buttons */
  radios = $(".fonttype"); // wijzig naar button
  var font_alignments = $('.text_alignment');
  if (radios && font_alignments) {
    for (var i = 0, max = radios.length; i < max; i++) {
      radios[i].onclick = function () {
        if (this.id == "text-cmd-bold") {
          if (canvas.getActiveObject().fontWeight != 'bold') {
            canvas.getActiveObject().set("fontWeight", "bold");
            $("#" + this.id).addClass('active_font_selector');
          }
          else {
            canvas.getActiveObject().set("fontWeight", "");
            $("#" + this.id).removeClass('active_font_selector');
          }
        }
        if (this.id == "text-cmd-italic") {
          if (canvas.getActiveObject().fontStyle != 'italic') {
            canvas.getActiveObject().set("fontStyle", "italic");
            $("#" + this.id).addClass('active_font_selector');
          }
          else {
            canvas.getActiveObject().set("fontStyle", "");
            $("#" + this.id).removeClass('active_font_selector');
          }
        }
        if (this.id == "text-cmd-underlined") {
          if (canvas.getActiveObject().underline) {
            canvas.getActiveObject().set("underline", false);
            $("#" + this.id).removeClass('active_font_selector');
          }
          else {
            canvas.getActiveObject().set("underline", true);
            $("#" + this.id).addClass('active_font_selector');
          }
        }
        canvas.renderAll();
        set_hidden_field(canvas);
      }
    }
    /* font alingnment */

    for (var i = 0; i < font_alignments.length; i++) {
      font_alignments[i].onclick = function () {
        font_alignments.removeClass('active_font_selector');
        $("#" + this.id).addClass('active_font_selector');
        if (this.id == 'talign_left') {
          canvas.getActiveObject().set('textAlign', "left")
        } else if (this.id == 'talign_center') {
          canvas.getActiveObject().set('textAlign', "center")
        }
        else {
          canvas.getActiveObject().set('textAlign', "right")
        }
        canvas.renderAll();
        set_hidden_field(canvas);
      }
    }
  }
})

/*Initialize canvas with previous data */
function initilizeCanvas(canvas) {
  var field_data = $('#preview_canvas').data('fields-data');
  var canvas_data = $('#preview_canvas').data('canvas-data');
  var preview_canvas_data = canvas_data ? canvas_data : field_data


  if (preview_canvas_data) {
    if (canvas_data) {
      var user_field_data = field_data.filter(obj => obj.field_type == 'text');
      var canvas_field_data = canvas_data.objects.filter(obj => obj.code == "user")
      if (user_field_data > canvas_field_data) {
        genarate_from_input(field_data, canvas);
      } else {
        fabric.Textbox.prototype.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              code: this.code,
              field_id: this.field_id
            });
          };
        })(fabric.Textbox.prototype.toObject);

        fabric.Image.prototype.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              code: this.code,
              field_id: this.field_id
            });
          };
        })(fabric.Image.prototype.toObject);
        canvas.loadFromJSON(canvas_data);
      }
    } else {
      genarate_from_input(field_data, canvas);
    }
  }
}

function genarate_from_input(preview_canvas_data, canvas) {
  var image_url_data = $('#preview_canvas').data('image-url');
  preview_canvas_data.map((c_data, index) => {
    var object_length = canvas.getObjects().length;
    if (c_data.field_type == 'text') {
      var textbox = new fabric.Textbox(c_data.default_value ? c_data.default_value : 'Add text', {
        left: c_data.x_position ? c_data.x_position : (50 + (index * 100)),
        top: c_data.y_position ? c_data.y_position : 50,
        width: 100,
        scaleX: c_data.width ? c_data.width : 1,
        scaleY: c_data.height ? c_data.height : 1,
        fontSize: c_data.font_size ? c_data.font_size : 20,
        fontFamily: c_data.font_family ? c_data.font_family : 'Times New Roman',
        angle: c_data.angle ? c_data.angle : 0,
        fontStyle: c_data.font_style ? c_data.font_style : "",
        fontWeight: c_data.font_weight ? c_data.font_weight : "",
        canvas_id: index
      });
      textbox.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            code: this.code,
            field_id: this.field_id
          });
        };
      })(textbox.toObject);
      canvas.add(textbox);
      textbox.code = c_data.code;
      textbox.field_id = c_data.id
      canvas.renderAll();
    }
    else {
      if (c_data.name == 'background') {
        fabric.Image.fromURL(image_url_data[index], function (img) {
          // add background image
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height
          });
        });
      }
      else {
        fabric.Image.fromURL(image_url_data[index], function (img) {
          var oImg = img.set({
            left: c_data.x_position ? c_data.x_position : (50 + (index * 100)),
            top: c_data.y_position ? c_data.y_position : 50,
            angle: c_data.angle ? c_data.angle : 0,
            scaleX: c_data.width ? c_data.width : 0.5,
            scaleY: c_data.height ? c_data.height : 0.5,
            canvas_id: index
          });
          oImg.toObject = (function (toObject) {
            return function () {
              return fabric.util.object.extend(toObject.call(this), {
                code: this.code,
                field_id: this.field_id
              });
            };
          })(oImg.toObject);
          canvas.add(oImg);
          oImg.code = c_data.code;
          oImg.field_id = c_data.id;
        });
      }
    }
  })
}


function save_image_to_server(canvas, file, object_length, image_type = "") {
  var preview_canvas_data = $('#preview_canvas').data('fields-data');
  var formData = new FormData();
  if (image_type == 'background') {
    formData.append("certificate_template[certificate_template_fields_attributes][0][name]", "background");
  }
  else {
    formData.append("certificate_template[certificate_template_fields_attributes][0][name]", "image" + object_length);
  }
  formData.append("certificate_template[certificate_template_fields_attributes][0][field_type]", "image");
  formData.append("certificate_template[certificate_template_fields_attributes][0][code]", "default");
  formData.append('certificate_template[certificate_template_fields_attributes][0][attachment]', file);
  var new_image_url
  $.ajax({
    url: "/admin/certificate_templates/" + preview_canvas_data[0].certificate_template_id + "/templates_build/image_file_upload",
    type: 'POST',
    data: formData,
    dataType: "json",
    processData: false,  // tell jQuery not to process the data
    contentType: false,  // tell jQuery not to set contentType
    success: function (data) {
      new_image_url = data.img_url
      if (image_type != 'background') {
        fabric.Image.fromURL(new_image_url, function (img) {
          var oImg = img.set({ left: 0, top: 0, angle: 0, canvas_id: object_length }).scale(0.5);
          oImg.toObject = (function (toObject) {
            return function () {
              return fabric.util.object.extend(toObject.call(this), {
                code: this.code,
                field_id: this.field_id
              });
            };
          })(oImg.toObject);
          canvas.add(oImg);
          oImg.code = 'default';
          oImg.field_id = "";
          canvas.renderAll();
          canvas.setActiveObject(oImg);
        });
      }
      else {
        fabric.Image.fromURL(new_image_url, function (img) {
          // add background image
          fabric.Object.NUM_FRACTION_DIGITS = 10;
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: (canvas.width / img.width),
            scaleY: (canvas.height / img.height)
          });
        });
      }
    }
  });
}

function set_input_fields(active_obj) {
  active_obj.fontWeight == 'bold' ? $('#text-cmd-bold').addClass('active_font_selector') : $('#text-cmd-bold').removeClass('active_font_selector')
  active_obj.fontStyle == 'italic' ? $('#text-cmd-italic').addClass('active_font_selector') : $('#text-cmd-italic').removeClass('active_font_selector')
  active_obj.underline ? $('#text-cmd-underlined').addClass('active_font_selector') : $('#text-cmd-underlined').removeClass('active_font_selector')
  $('#font_size').val(parseInt(active_obj.fontSize));
  $('.text_alignment').removeClass('active_font_selector');
  $("#talign_" + active_obj.textAlign).addClass('active_font_selector');
  $("#font_color").val(active_obj.fill == "rgb(0,0,0)" ? "#000000" : active_obj.fill);
  $('#font_family').val(active_obj.fontFamily);
  M.FormSelect.init($('#font_family'));
  set_fontstyle_dropdown();
  $('#textControls').attr('disabled', false);
}

function set_fontstyle_dropdown() {
  var list_items = $("#font_style_select").find("li");
  if (list_items) {
    for (var i = 0; i < list_items.length; i++) {
      var list_item = $("#" + list_items[i].id);
      list_item.css({ "font-family": list_item.first().text() })
    }
  }
}

function set_hidden_field(canvas) {
  $("#certificate_template_canvas_data").val(JSON.stringify(canvas));
  $("#certificate_template_pre_image").val(canvas.toDataURL('image/png'));
}

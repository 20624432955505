$(document).on('ready', function () {
  var contineer = $('.preview_canvas_container');
  $(document).on('change', '#csv_file', function (e) {
    if (e.target.files.length > 0) {
      $('#submit_bulk_certificate').css('display', 'block')
    }
  })

  /*create new canvas*/
  var user_canvas = new fabric.Canvas('user_preview_canvas');
  user_canvas.setDimensions({ width: contineer.width(), height: contineer.height() });

  /*initialize with previous data*/


  user_canvas.on('after:render', function (options) {
    canvas_field_id = $('#disabled').data('id');
    user_canvas.getObjects().map(object => {
      if (object.code != "user") {
        object.set("selectable", false)
      }
      if (object.field_id === canvas_field_id){
        object.set("text", $('#disabled').val());
        object.set("selectable", false)
      }
    });
  });

  initilizeUserCanvas(user_canvas);

  
  /*Event handler */

  $(document).on('change key keypress keydown keyup', '.field_of_text', function (e) {
    var field_id = $(this).data('id');
    var filtered_obj = user_canvas.getObjects().filter(obj => obj.field_id == field_id)
    filtered_obj[0].set("text", $(this).val());
    // UpdateLicensekey
    user_canvas.renderAll();
  })

  // function UpdateLicensekey(){
  //   var field_id = $('#disabled').data(id);
  //   var filtered_obj = user_canvas.getObjects().filter(obj => obj.field_id == field_id)
  //   filtered_obj[0].set("text", $('#disabled').val());
  // }



  $(document).on('click', '#canvas_submit', function () {
    var preview_canvas_data = $('#user_preview_canvas').data('fields-data');
    var formData = new FormData();
    var blob = dataURItoBlob(user_canvas.toDataURL("image/png"));
    var resultFile = new File([blob], "certificate");
    formData.append('certificate[preview_image]', resultFile);
    formData.append('certificate[user_canvas_data]', JSON.stringify(user_canvas));
    formData.append('certificate[certificate_template_id]', preview_canvas_data[0].certificate_template_id)
    formData.append('certificate[contact_number]', $('#contact_inline').val())
    formData.append('certificate[first_name]', $('#first_name_inline').val())
    formData.append('certificate[last_name]', $('#last_name_inline').val())
    formData.append('certificate[email]', $('#email_inline').val())
    formData.append('certificate[batch]', $('#certificate_batch').val())


    $('.field_of_text').each((i, v) => {
      console.log({ name: v.name, value: v.value })
      formData.append(v.name, v.value);
    })

    $.ajax({
      url: "/certificates",
      type: 'POST',
      data: formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,
      dataType: "json",
      success: function (data) {
        window.location.href = window.location.origin + '/certificates/' + data.id
      }
    });
  })
})

function initilizeUserCanvas(user_canvas) {
  var preview_canvas_data = $('#user_preview_canvas').data('fields-data');
  var user_canvas_data = $('#user_preview_canvas').data('canvas-data');
  var image_url_data = $('#user_preview_canvas').data('image-url');
  if (user_canvas_data) {
    user_canvas.loadFromJSON(user_canvas_data);
  }

}

function dataURItoBlob(dataURI) {
  /* convert base64 to raw binary data held in a string
   doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this*/
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

window.displayClock = function () {
  console.log("generat on csv");
  var cont = $("#hidden_canvas")
  $('.not_image_canvas').each((k, elem) => {
    console.log(" csv");
    var data = $("#" + elem.id).data('canvas-data');
    var id = $("#" + elem.id).data('id');
    $('#hidden_canvas').append("<canvas id='canvas_" + elem.id + "'></canvas>").hide();
    var hidden_canvas = new fabric.Canvas("canvas_" + elem.id);
    hidden_canvas.setDimensions({ width: cont.width(), height: cont.height() });
    hidden_canvas.loadFromJSON(data, function () {
     console.log("generat");
      var formData = new FormData();
      var blob = dataURItoBlob(hidden_canvas.toDataURL("image/png"));
      var resultFile = new File([blob], "certificate");
      formData.append('certificate[preview_image]', resultFile);
      console.log(formData);
      $.ajax({
        url: "/certificates/" + id,
        type: 'PATCH',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,
        dataType: "json",
        success: function (data) {

        }
      });
      $(location).prop('href', window.location.origin + '/certificates')
    });
    //$(location).prop('href', window.location.origin + '/certificates')
  })

}
